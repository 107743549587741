<template>
	<v-chip
		v-bind="$attrs"
		:class="{
			'v-chip-with-label': true,
			'v-chip-with-label-left': labelText.trim() !== '' && labelPosition === 'left',
			'v-chip-with-label-right': labelText.trim() !== '' && labelPosition === 'right'
		}"
		v-on="$listeners"
	>
		<template v-if="labelPosition">
			<v-chip v-if="labelText.trim() !== ''" class="label" :color="labelColor" :text-color="labelTextColor">
				{{ labelText }}
			</v-chip>
			<slot></slot>
		</template>
		<template v-else>
			<v-chip v-if="labelText.trim() !== ''" :color="labelColor" :text-color="labelTextColor">
				{{ labelText }}
			</v-chip>
			<slot></slot>
		</template>
	</v-chip>
</template>

<script>
export default {
	name: 'ChipsWithLabel',
	props: {
		labelColor: {
			required: false,
			type: String,
			default: 'primary'
		},
		labelTextColor: {
			required: false,
			type: String,
			default: 'white'
		},
		labelText: {
			required: false,
			type: String,
			default: ''
		},
		labelPosition: {
			required: false,
			type: String,
			default: 'left'
		}
	}
}
</script>

<style>
.v-chip.v-chip-with-label.v-chip-with-label-left > .v-chip__content {
	padding-left: 0;
	padding-right: 14px;
}

.v-chip.v-chip-with-label.v-chip-with-label-right > .v-chip__content {
	padding-right: 0;
	padding-left: 14px;
}

.v-chip.v-chip-with-label.v-chip-with-label-left > .v-chip__content > .v-chip {
	margin-left: 0;
	margin-right: 10px;
}

.v-chip.v-chip-with-label.v-chip-with-label-right > .v-chip__content > .v-chip {
	margin-right: 0;
	margin-left: 10px;
}
</style>
